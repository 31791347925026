import React, { SyntheticEvent } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Typography,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ButtonProgressContainer from './form/button-progress-container';
import CircularProgressOverlay from './form/circular-progress-overlay';

import { User } from '../../@types/user';
import useAuth from '../../State/Auth/useAuth';

interface RequestState {
  loading: boolean;
  successful: boolean;
  error: boolean;
  errorCode: string;
  errorMessage: string;
}

const createUsernameRequest = (
  token: string,
  username: string,
  password: string
): Promise<Response> =>
  // fetch('/.netlify/functions/add-username', {
  fetch(`${process.env.GATSBY_FUNCTIONS_URL}/addUsername`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ username, password }),
  });

interface UsernameCreateDialog {
  setUser: (user: User) => void;
  onClose: () => void;
}

const UsernameCreateDialog = ({ setUser, onClose }: UsernameCreateDialog): JSX.Element => {
  const auth = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const [form, setForm] = React.useState({
    username: '',
    password: '',
  });
  const [request, setRequest] = React.useState<RequestState>({
    loading: false,
    successful: false,
    error: false,
    errorCode: '',
    errorMessage: '',
  });

  const onCreateUsername = async (): Promise<void> => {
    setRequest({
      error: false,
      successful: false,
      errorCode: '',
      errorMessage: '',
      loading: true,
    });
    const token = await auth.getToken();
    if (token) {
      const result = await createUsernameRequest(token, form.username, form.password);
      if (result.status === 200) {
        const json = await result.json();
        setUser(json);
        setRequest({
          error: false,
          successful: true,
          errorCode: '',
          errorMessage: '',
          loading: false,
        });
      } else if (result.status === 400) {
        const json = await result.json();
        setRequest({
          error: true,
          successful: false,
          errorCode: json.code,
          errorMessage: json.message,
          loading: false,
        });
      } else {
        setRequest({
          error: true,
          successful: false,
          errorCode: '',
          errorMessage: 'An error has occured, please try again',
          loading: false,
        });
      }
    }
  };

  const usernameRequestError =
    request.errorCode === 'auth/username' ? request.errorMessage : undefined;
  const passwordRequestError =
    request.errorCode === 'auth/password' ? request.errorMessage : undefined;
  const requestError = !request.errorCode ? request.errorMessage : undefined;

  return (
    <>
      <Dialog open={!request.successful} fullWidth>
        <DialogTitle>Create username</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter a username below</DialogContentText>
          <FormControl fullWidth error={!!usernameRequestError} margin="normal">
            <InputLabel htmlFor="standard-adornment-password">Username</InputLabel>
            <Input
              fullWidth
              id="standard-adornment-password"
              type="text"
              value={form.username}
              name="username"
              onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
                setForm({ ...form, username: e.currentTarget.value })
              }
              error={!!usernameRequestError}
            />
            {usernameRequestError && (
              <Typography variant="body2" color="textSecondary" component="p">
                {usernameRequestError}
              </Typography>
            )}
          </FormControl>
          <FormControl fullWidth error={!!passwordRequestError} margin="normal">
            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
            <Input
              fullWidth
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={form.password}
              name="password"
              onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
                setForm({ ...form, password: e.currentTarget.value })
              }
              error={!!passwordRequestError}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(): void => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {passwordRequestError && (
              <Typography variant="body2" color="textSecondary" component="p">
                {passwordRequestError}
              </Typography>
            )}
          </FormControl>
          {requestError && (
            <Typography variant="body2" color="textSecondary" component="p">
              {requestError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={request.loading} color="secondary" onClick={onClose}>
            Close
          </Button>
          <ButtonProgressContainer>
            <Button disabled={request.loading} color="primary" onClick={onCreateUsername}>
              Create
            </Button>
            {request.loading && <CircularProgressOverlay size={30} />}
          </ButtonProgressContainer>
        </DialogActions>
      </Dialog>
      <Dialog open={request.successful} fullWidth>
        <DialogTitle>Username created</DialogTitle>
        <DialogContent>
          <DialogContentText>yes</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsernameCreateDialog;
