import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const CircularProgressOverlay = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
`;

export default CircularProgressOverlay;
