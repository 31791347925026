import { useEffect } from 'react';
import { navigate } from 'gatsby';
import useAuth from '../State/Auth/useAuth';

const useRequireAuth = (redirectUrl = '/login') => {
  const auth = useAuth();
  useEffect(() => {
    if (!auth.initialized) {
      return;
    }
    if (!auth.firebaseUser) {
      navigate(redirectUrl);
    }
  }, [auth]);

  return auth;
};

export default useRequireAuth;
