import React, { useMemo } from 'react';
import { Box, Card, Grid, Typography, CardContent } from '@material-ui/core';
import { RedisAccount } from '../../../@types/Redis';

interface MetricsRedisSummaryProps {
  accounts: RedisAccount[];
}

const MetricsRedisSummary = ({ accounts }: MetricsRedisSummaryProps) => {
  const activeAccounts = useMemo(() => accounts.length || 0, [accounts]);
  const activeUsers = useMemo(() => accounts?.reduce((a, b) => (a += b.active), 0) || 0, [
    accounts,
  ]);

  return (
    <Box mt={2} mb={2} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Active accounts
              </Typography>
              <Typography color="textSecondary">{activeAccounts}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Active users
              </Typography>
              <Typography color="textSecondary">{activeUsers}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MetricsRedisSummary;
