import React from 'react';
import useRequireAuth from '../auth/useRequireAuth';
import Account from '../components/account';

const AccountPage = (): JSX.Element | null => {
  useRequireAuth();
  return <Account />;
};

export default AccountPage;
