import React from 'react';
import UsersTable from './UsersTable';
import { Box } from '@material-ui/core';
import MetricsRedis from './MetricsRedis';
import useAuth from '../../../State/Auth/useAuth';

const Admin = () => {
  const { admin } = useAuth();
  if (!admin) {
    return null;
  }
  return (
    <Box mt={2} mb={2}>
      <Box mt={2} mb={2}>
        <UsersTable />
      </Box>
      <Box mt={2} mb={2}>
        <MetricsRedis />
      </Box>
    </Box>
  );
};

export default Admin;
