exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-development-tsx": () => import("./../../../src/pages/development.tsx" /* webpackChunkName: "component---src-pages-development-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-legal-page-tsx": () => import("./../../../src/templates/LegalPage.tsx" /* webpackChunkName: "component---src-templates-legal-page-tsx" */),
  "component---src-templates-member-page-tsx": () => import("./../../../src/templates/MemberPage.tsx" /* webpackChunkName: "component---src-templates-member-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-release-tsx": () => import("./../../../src/templates/release.tsx" /* webpackChunkName: "component---src-templates-release-tsx" */),
  "component---src-templates-releases-tsx": () => import("./../../../src/templates/releases.tsx" /* webpackChunkName: "component---src-templates-releases-tsx" */)
}

