import React from 'react';
import AuthProvider from '../State/Auth/AuthProvider';
import RedisProvider from '../State/Redis/RedisProvider';
import App from './app';
import Layout from './layout';

interface Base {
  children: JSX.Element;
}

const Base = ({ children }: Base): JSX.Element => (
  <AuthProvider>
    <RedisProvider>
      <App>
        <Layout>{children}</Layout>
      </App>
    </RedisProvider>
  </AuthProvider>
);

export default Base;
