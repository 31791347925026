import React from 'react';
import moment from 'moment';
import { Button, Card, CardContent, Box, Typography, CardActions, List } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { Subscription, User } from '../../@types/user';
import AccountListItem from './AccountListItem';
import useAuth from '../../State/Auth/useAuth';

const getLicensesInUseRequest = async (token: string): Promise<Response> =>
  // await fetch('/.netlify/functions/get-license-status', {
  fetch(`${process.env.GATSBY_FUNCTIONS_URL}/getLicenseStatus`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

interface Licenses {
  user?: User | null;
  subscription: Subscription | undefined;
  loading: boolean;
}

const Licenses = ({ user, subscription, loading }: Licenses): JSX.Element | null => {
  const auth = useAuth();
  const [loadingInUse, setLoadingInUse] = React.useState<boolean>(false);
  const [licensesInUse, setLicensesInUse] = React.useState<number | undefined>();

  const getLicensesInUse = React.useCallback(async (): Promise<void> => {
    setLoadingInUse(true);
    const token = await auth.getToken();
    if (token) {
      const response = await getLicensesInUseRequest(token);
      const body = await response.json();
      setLicensesInUse(body.licensesInUse);
    }
    setLoadingInUse(false);
  }, []);

  const exclusiveLicenses = user?.exclusiveLicenses || 0;
  const manuallySubscribedLicenses = user?.manuallySubscribedLicenses || 0;
  const subscribedLicenses = subscription?.quantity || 0;
  const licenses = exclusiveLicenses + manuallySubscribedLicenses + subscribedLicenses;

  React.useEffect(() => {
    if (licenses > 0) {
      getLicensesInUse();
    }
  }, [subscription, licenses]);

  return (
    <Box mt={2} mb={2}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Licenses
          </Typography>
          {subscription && subscription.deleted && (
            <Typography variant="body2" color="textSecondary" component="p">
              Subscription has been removed. Licences will be available until{' '}
              {moment(subscription.currentPeriodEnd * 1000).format('YYYY-MM-DD')}
            </Typography>
          )}
          <List>
            {exclusiveLicenses > 0 && (
              <AccountListItem
                primary="Exclusive licenses"
                secondary={exclusiveLicenses}
                loading={loading}
                skeletonWidth="20px"
                avatar={<VpnKeyIcon />}
              />
            )}
            {manuallySubscribedLicenses > 0 && (
              <AccountListItem
                primary="Manually subscribed licenses"
                secondary={manuallySubscribedLicenses}
                loading={loading}
                skeletonWidth="20px"
                avatar={<VpnKeyIcon />}
              />
            )}
            {subscribedLicenses > 0 && (
              <AccountListItem
                primary="Subscribed licenses"
                secondary={subscribedLicenses}
                loading={loading}
                skeletonWidth="20px"
                avatar={<VpnKeyIcon />}
              />
            )}
            {licenses > 0 && (
              <AccountListItem
                primary="Licenses in use"
                secondary={licensesInUse}
                loading={loadingInUse}
                skeletonWidth="20px"
                avatar={<VpnKeyIcon />}
              />
            )}
          </List>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            disabled={loading || loadingInUse}
            onClick={getLicensesInUse}
          >
            Refresh
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default Licenses;
