import React, { useState } from 'react';
import { Button, Box, Card, CardActions, CardContent, Typography, List } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import UsernameCreateDialog from './username-create-dialog';
import UsernameChangePasswordDialog from './username-change-password-dialog';
import { User } from '../../@types/user';
import AccountListItem from './AccountListItem';

const Username = () => {
  const [user, setUser] = useState<User | null>(null);
  const [create, setCreate] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const username = user?.username;

  return (
    <>
      <Box mt={2} mb={2}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Username
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              A username only gives access to BIMXplorer software. This is good if you do not want
              to share your email and password with others.
            </Typography>
            {username && (
              <List>
                <AccountListItem
                  primary="Username"
                  secondary={username}
                  loading={!user}
                  skeletonWidth="100px"
                  avatar={<AccountCircleIcon />}
                />
                <AccountListItem
                  primary="Password"
                  secondary="******"
                  loading={!user}
                  skeletonWidth="100px"
                  avatar={<LockIcon />}
                />
              </List>
            )}
          </CardContent>
          <CardActions>
            {!username && (
              <Button size="small" color="primary" onClick={(): void => setCreate(!create)}>
                Create username
              </Button>
            )}
            {username && (
              <Button
                size="small"
                color="primary"
                onClick={(): void => setChangePassword(!changePassword)}
              >
                Change password
              </Button>
            )}
          </CardActions>
        </Card>
      </Box>
      {create && <UsernameCreateDialog setUser={setUser} onClose={(): void => setCreate(false)} />}
      {changePassword && (
        <UsernameChangePasswordDialog onClose={(): void => setChangePassword(false)} />
      )}
    </>
  );
};

export default Username;
