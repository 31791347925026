import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

interface DialogTitle {
  children: React.ReactNode;
  disabled?: boolean;
  onClose: () => void;
}

const DialogTitle = ({ children, disabled, onClose }: DialogTitle): JSX.Element => (
  <MuiDialogTitle disableTypography>
    <Typography variant="h6">{children}</Typography>
    {onClose && (
      <CloseButton aria-label="close" onClick={onClose} disabled={disabled}>
        <CloseIcon />
      </CloseButton>
    )}
  </MuiDialogTitle>
);

export default DialogTitle;
