import React from 'react';
import { ListItem, ListItemAvatar, Avatar, Divider, ListItemText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface AccountListItemProps {
  primary: string;
  secondary?: string | number | null;
  loading: boolean;
  skeletonWidth: string;
  avatar: JSX.Element;
}

const AccountListItem = ({
  primary,
  secondary,
  loading,
  skeletonWidth,
  avatar,
}: AccountListItemProps): JSX.Element => (
  <>
    <ListItem>
      <ListItemAvatar>
        <Avatar>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        secondary={
          loading ? (
            <Skeleton
              animation="wave"
              height={10}
              width={skeletonWidth}
              style={{ marginTop: '5px', marginBottom: '5px' }}
            />
          ) : (
            secondary
          )
        }
      />
    </ListItem>
    <Divider variant="inset" component="li" />
  </>
);

export default AccountListItem;
