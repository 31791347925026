import React, { useEffect, useState } from 'react';
import SEO from '../seo';
import { Container, Box, Tabs, Tab, Typography } from '@material-ui/core';
import VerifyEmail from './verify-email';
import AccountInformation from './account-information';
import Licenses from './licences';
import Simulate from './simulate';
import Username from './username';
import Admin from './admin';
import useAuth from '../../State/Auth/useAuth';

interface AllyProps {
  id: string;
  'aria-controls': string;
}

const a11yProps = (index: number): AllyProps => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

const Account = (): JSX.Element | null => {
  const { initialized, firebaseUser, admin, user, requestGetUser, getUser } = useAuth();
  const [value, setValue] = useState(0);
  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number): void =>
    setValue(newValue);

  useEffect(() => {
    if (!initialized) {
      return;
    }
    if (firebaseUser) {
      getUser();
    }
  }, [firebaseUser, initialized]);

  if (!firebaseUser || !initialized) return null;

  const emailVerified = firebaseUser && firebaseUser.emailVerified;

  return (
    <>
      <SEO title="Login" />
      <Container maxWidth="md">
        <Box mt={2} mb={2}>
          <>
            {emailVerified && (
              <Tabs
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Account tabs"
                scrollButtons="auto"
              >
                <Tab label="General" {...a11yProps(0)} />
                <Tab label="Licenses" {...a11yProps(1)} />
                <Tab label="Username" {...a11yProps(2)} />
                {admin && <Tab label="Admin" {...a11yProps(3)} />}
              </Tabs>
            )}
            <TabPanel value={value} index={0}>
              <VerifyEmail />
              <AccountInformation />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Licenses
                loading={requestGetUser.loading}
                user={user}
                subscription={user && user.subscription ? user.subscription : undefined}
              />
              <Simulate />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Username />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Admin />
            </TabPanel>
          </>
        </Box>
      </Container>
    </>
  );
};

export default Account;
